import React, { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

const fall = keyframes`
  0% { transform: translateY(-100px); }
  100% { transform: translateY(100vh); }
`;

const Snowflake = styled.div`
  position: absolute;
  top: 0;
  left: ${({ left }) => left}%;
  font-size: ${({ size }) => size}px;
  opacity: ${({ opacity }) => opacity};
  animation: ${fall} ${({ duration }) => duration}s linear infinite;
  pointer-events: none;
`;

const Snowflakes = () => {
  useEffect(() => {
    const snowflakes = Array.from({ length: 50 }, (_, i) => ({
      id: i,
      left: Math.random() * 90, // чтобы за пределы контейнеров не выходили
      size: Math.random() * 10 + 10,
      opacity: Math.random(),
      duration: Math.random() * 5 + 5,
    }));

    setSnowflakes(snowflakes);
  }, []);

  const [snowflakes, setSnowflakes] = React.useState([]);

  return (
    <>
      {snowflakes.map(snowflake => (
        <Snowflake
          key={snowflake.id}
          left={snowflake.left}
          size={snowflake.size}
          opacity={snowflake.opacity}
          duration={snowflake.duration}
        >
          ❄
        </Snowflake>
      ))}
    </>
  );
};

export default Snowflakes;