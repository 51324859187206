import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 2rem;
  color: #fff;
  background: #1a1a1a;
  min-height: 100vh;
`;

const Title = styled.h1`
  margin-top: 80px;
  color: #fff;
  text-align: center;
  font-family: 'Acrom', sans-serif;
`;

const Paragraph = styled.p`
  text-align: left;
  line-height: 1.6;
  font-family: 'Montserrat', sans-serif;
  max-width: 900px;
  margin: 0 auto;
`;

const TermsOfService = () => (
  <Container>
    <Title>Пользовательское соглашение</Title>
    <Paragraph>
      <b>1. Принятие условий.</b>
      <br />1.1. Настоящее пользовательское соглашение регулирует взаимоотношения ИП Хазиев Михаил Анварович (ИНН: 772148147348, ОГРНИП: 324774600285081) (далее — Администратор) и пользователей сайта https://premier-game.ru.
      <br />1.2. Настоящие правила являются документом, обязательным к ознакомлению каждому пользователю, обратившегося к услугам платного приобретения привилегий на игровом сервере.
      <br />1.3. Если пользователь не согласен с каким-либо положением настоящих правил или ощущает вероятность негативных для пользователя последствий, ему рекомендуется отказаться от услуги приобретения привилегий на игровом сервере.
      <br />1.4. Факт обращения инициации пользователем процесса приобретения привилегий на игровом сервере считается подтверждением того, что пользователь ознакомлен и согласен с каждым пунктом настоящих правил.
      <br /><br />
      <b>2. Общие положения.</b>
      <br />2.1. Собственники, владельцы, администрация, представители проекта не несут никакой ответственности за ущерб морального, либо материального характера, который может нанести прямо либо опосредованно предлагаемый игровой сервер, а также за любые неточности, ошибки, дефекты и сбои работы сайта, платежной системы или игрового сервера, вне зависимости от причин их вызвавших.
      <br />2.2. Инициируя процесс приобретения привилегий на игровом сервере, пользователь подтверждает свое согласие не возлагать ответственность, возможные убытки и ущерб, связанные с пользованием сайтом, либо игровым сервером, на его собственников, владельцев и администрацию.
      <br />2.3. В случае нанесения пользователем ущерба проекту, администрация, собственники и владельцы проекта имеют право требовать возмещения такого ущерба и выплаты соответствующих компенсаций.
      <br />2.4. В случае несоответствия какого-либо положения настоящих правил требованиям действующего законодательства, оно считается замененным близким по содержанию положением действующего законодательства. При этом все остальные положения настоящих правил сохраняют свою силу.
      <br />2.5. Курс обмена в нашей системе установлен следующим образом: 100 бонусов равны 1 рублю. Это означает, что все платежи и стоимость внутриигровых услуг на нашем сайте выражены в игровых бонусах, соответствующих рублям по ранее указанной конвертации. Вы можете использовать бонусы для оплаты услуг или привилегий по указанному курсу обмена.
      <br /><br />
      <b>3. Исключение гарантий.</b>
      <br />3.1. Услуги предоставляются «как есть» и представители, администрация, владельцы и собственники проекта не предоставляют никаких гарантий в отношении их предоставления.
      <br />3.2. Представители, администрация, владельцы и собственники проекта имеют право на блокирование и/или удаление аккаунта без предупреждения пользователя.
      <br /><br />
      <b>4. Политика возврата денежных средств.</b>
      <br />4.1. Услуги считаются оказанными администрацией и принятыми пользователем в момент заказа услуг.
      <br />4.2. В случае если пользователь на протяжении 1 (одного) рабочего дня с моментапринятия услуг не направил администрации мотивированный отказ от приемки услуг, стороны пришли к соглашению считать услуги оказанными качественно и в полном объеме.
      <br />4.3. В случае отказа от услуг, пользователь обязуется направить мотивированное обоснование на электронную почту администратора: support@premier-game.ru, администратор не позднее 10 (десяти) рабочих дней с момента получения мотивированного отказа от пользователя обязан рассмотреть его и уведомить о решении посредством электронной почты, указанной при регистрации пользователя в сервисе.
      <br />4.4. В случае принятия администратором мотивированного отказа, последний обязуется осуществить возврат денежных средств пользователею в виде доступных игровых бонусов в эквиваленте оплаченной денежной суммы.
      <br />4.5. Возврат средств по настоящей Оферте не предусмотрен, если пользователь не предоставил мотивированный отказ от услуг в срок, предусмотренный настоящей Офертой.
      <br />4.6 Возврат не возможен, когда пользователь, который производил оплату ценности (игровая валюта) будут израсходованы или частично израсходованы.
      <br /><br />
      <b>5. Изменение условий.</b>
      <br />5.1. Администрация, владельцы и собственники проекта имеет право вносить изменения в данный документ без предварительного уведомления пользователей.
      <br /><br />
      <b>Меры безопасности при приеме платежей</b>
      <br />• При оплате заказа банковской картой, обработка платежа (включая ввод номера карты) происходит на защищенной странице процессинговой системы, которая прошла международную сертификацию. Это значит, что Ваши конфиденциальные данные (реквизиты карты, регистрационные данные и др.) не поступают в интернет-магазин, их обработка полностью защищена и никто, в том числе наш интернет-магазин, не может получить персональные и банковские данные клиента.
      <br />• При работе с данными карт применяется стандарт защиты информации, разработанный международными платёжными системами Visa и MasterCard - Payment Card Industry Data Security Standard (PCI DSS), что обеспечивает безопасную обработку реквизитов Банковской карты Держателя. Применяемая технология передачи данных гарантирует безопасность по сделкам с Банковскими картами путем использования протоколов TLS (Transport Layer Security), Verified by Visa, Secure Code, и закрытых банковских сетей, имеющих высшую степень защиты.
      <br />
      <br /><b>К оплате принимаются платежные карты: VISA, MasterCard, Maestro, МИР. Для оплаты товара банковской картой при оформлении заказа в интернет-магазине выберите способ оплаты: банковской картой. При оплате заказа банковской картой, обработка платежа происходит на авторизационной странице банка, где Вам необходимо ввести данные Вашей банковской карты:</b>
      <br />• Тип карты
      <br />• Номер карты
      <br />• Срок действия карты
      <br />• Имя держателя карты (латинскими буквами, точно также как указано на карте)
      <br />• CVC2/CVV2 код
      <br />Если Ваша карта подключена к услуге 3D-Secure, Вы будете автоматически переадресованы на страницу банка, выпустившего карту, для прохождения процедуры аутентификации. Информацию о правилах и методах дополнительной идентификации уточняйте в Банке, выдавшем Вам банковскую карту.
    </Paragraph>
  </Container>
);

export default TermsOfService;
