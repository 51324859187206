import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Element } from 'react-scroll';
import axios from 'axios';
import Header from './components/Header';
import Footer from './components/Footer';
import GlobalStyles from './styles';
import ScrollbarStyles from './scrollbarStyles';
import ScrollToTop from './components/ScrollToTop';
import HeroSection from './components/HeroSection';
import HowToStart from './components/HowToStart';
import SocialLinks from './components/SocialLinks';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';
import Contacts from './components/Contacts';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Shop from './components/Shop';
import Map from './components/Map';
import ForgotPassword from './components/ForgotPassword';
import FAQ from './components/FAQ';
import MaintenancePage from './components/MaintenancePage';
import PaymentSuccess from './components/PaymentSuccess';
import PaymentFailure from './components/PaymentFailure';
import NotFound from './components/NotFound';
import Admin from './components/Admin';
import Notification from './components/Notification';
import Statistics from './components/Statistics';
import DonationStatisticsRoute from './components/DonationStatisticsRoute';
import Jobs from './components/Jobs';
import Snowflakes from './components/Snowflakes';

const checkAdminStatus = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/is-admin`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data.isAdmin;
  } catch (error) {
    console.error('Error checking admin status:', error);
    return false;
  }
};

const AdminRoute = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAdmin = async () => {
      const adminStatus = await checkAdminStatus();
      setIsAdmin(adminStatus);
      setLoading(false);
    };

    checkAdmin();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return isAdmin ? <Admin /> : <Navigate to="/login" />;
};

const Home = () => {
  return (
    <>
      <HeroSection />
      <Element name="how-to-start">
        <HowToStart />
      </Element>
      <Element name="social">
        <SocialLinks />
      </Element>
      <Notification />
    </>
  );
};

const App = () => {
  const isMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === 'true';

  return (
    <Router>
      <ScrollToTop />
      <GlobalStyles />
      <ScrollbarStyles />
      <Header />
      {isMaintenanceMode ? (
        <MaintenancePage />
      ) : (
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/map" element={<Map />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/payment-success" element={<PaymentSuccess />} />
          <Route path="/payment-failure" element={<PaymentFailure />} />
          <Route path="/statistics" element={<Statistics />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/admin" element={<AdminRoute />} />
          <Route path="/donation-statistics" element={<DonationStatisticsRoute />} />
          <Route path="/jobs" element={<Jobs />} />
        </Routes>
      )}
      <Footer />
    </Router>
  );
};

export default App;